<template>
  <UILoading v-if="loading" />
  <div v-else-if="statistieken" class="flex flex-col gap-8">
    <small class="-mb-4">Klanten zijn klantgegevens op basis van reservaties</small>
    <div class="card">
      <h2>Klanten - Aantal</h2>
      <div class="grid xl:grid-cols-4 gap-4">
        <BamTarget
          v-for="timeframe in Object.keys(statistieken)"
          :key="timeframe"
          :title="capitalize(timeframe)"
          :value="statistieken[timeframe].aantal_ritten"
        >
          <template #prefix>
            <ul>
              <li>{{ statistieken[timeframe].nieuw + statistieken[timeframe].terugkerend }} Klanten</li>
            </ul>
          </template>
          <template #suffix>
            <ul class="pt-2 border-t border-dashboard-dark">
              <li>{{ statistieken[timeframe].nieuw }} Nieuwe klanten</li>
              <li>{{ statistieken[timeframe].terugkerend }} Terugkerende klanten</li>
            </ul>
          </template>
        </BamTarget>
      </div>
    </div>

    <div class="card">
      <h2>Klanten - Maand overzicht</h2>
      <div>
        <BarStatsMulti
          :values="[
            Object.values(grafiek.daily).map(el => el.terugkerend || 0),
            Object.values(grafiek.daily).map(el => el.nieuw || 0),
          ]"
          :labels="['Terugkerende klanten', 'Nieuwe klanten']"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'

import BamTarget from '@/components/Charts/BamTarget.vue'
import UILoading from '@/components/UI/Loading.vue'

import { capitalize } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi.js'
import useRenderKey from '@/hooks/useRenderKey.js'

const props = defineProps({
  filters: Object,
  renderKey: [String, Number],
})

const BarStatsMulti = defineAsyncComponent(() => import('@/components/Charts/BarStatsMulti.vue'))

const { data, loading } = useGetApi('STATS_KLANTEN', props.filters, { watch: true })
useRenderKey(props.renderKey)

const statistieken = computed(() => data.value?.statistieken || {})
const grafiek = computed(() => data.value?.grafiek || { daily: {}, monthly: {}, yearly: {} })
</script>
